import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TarifasComponent implements OnInit {


  @Input() url: string;

  constructor() { }

  ngOnInit() {
    console.log(this.url);
  }

  ionViewDidEnter()
  {
    console.log(this.url);
  }

}
