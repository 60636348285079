import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Observable} from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  personaId: string;
  usuario: string;
  empresa: string;

  user={user:'',empresa:'',tipo:0,img:''};
  usertype=0;

  constructor(private router: Router,
    public menuCtrl: MenuController,
    private authService:UsuarioService,
    private platform: Platform,
    private usuarioService: UsuarioService) { }

  ngOnInit() {

    
    this.personaId = localStorage.getItem("personaId");
    this.usuario = localStorage.getItem("usuario");
    this.empresa = localStorage.getItem("empresa");
    this.authService.refresh();
    this.authService.activeUser.subscribe((_user)=>{
      if(_user)
        this.user = _user
      else
        this.user={user:'',empresa:'',tipo:0,img:''};
      
    });

  

  }

  
  logOut()
  {
    delete localStorage["personaId"];
    delete localStorage["usuario"];
    delete localStorage["usuarioId"];
    delete localStorage["email"];
    delete localStorage["empresa"];
    delete localStorage["rol"];
    delete localStorage["objetoUsuario"];
    this.authService.userLogout();
    this.user={user:'',empresa:'',tipo:0,img:''};
    this.menuCtrl.toggle();
    this.router.navigate(['/login']);
  }


  errorImg()
  {
    this.user.img = "https://digitalrising.com.gt/wp-content/uploads/2020/10/sinperfil.png";  

  }

}
