import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';
import { EpayService } from 'src/app/services/epay.service';
import { Pago } from 'src/app/models/pago';
import { Usuario } from 'src/app/models/usuario';
import { Suscripcion } from 'src/app/models/suscripcion';

@Component({
  selector: 'app-modal-suscripcion',
  templateUrl: './modal-suscripcion.component.html',
  styleUrls: ['./modal-suscripcion.component.scss'],
})
export class ModalSuscripcionComponent implements OnInit {


  pagoObj = new Pago(0,0,1,null,null,0,0,new Date());
  tipoPago: string = "2";
  formData: FormData = new FormData(); 
  montoDeposito: number = 100;
  usuarioObj: any;
  suscripcionPendiente: boolean;
  boletaURL: string;
  montoMembresia: number; 
  montoMembresiaSelect: number = 0;
  aniosMembresia: string = "0";
  suscripcionActual: Suscripcion = new Suscripcion(0,0,0,0,null, 0);

  constructor(  private epayService: EpayService,
    private toastCtrl: ToastController,
    private loading: LoadingService,
    private modalCtrl: ModalController) { }

  ngOnInit() {

  }

  ionViewDidEnter()
  {
    // console.log("usuario");
    // console.log(this.usuarioObj);
    this.boletaURL = "https://boletas.digitalrising.com.gt/" + this.pagoObj.pagoId + ".jpg"; 
    this.obtenerPrecioPlan();
  }

  obtenerPrecioPlan()
  {
    this.epayService.getPrecioPlan().subscribe(data =>
      {
        this.montoMembresia = data;
        this.montoMembresiaSelect = data;
        this.pagoObj.meses = 12;
      },
      error => {
        // console.log(error);
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      });
  }


  pagarDeposito()
  {
    // console.log("llego pagar deposito");
    this.loading.present();

    this.pagoObj.autorizacion = 0;
    this.pagoObj.monto = this.montoMembresia;
    this.pagoObj.usuarioId = this.usuarioObj.persona.personaId; // parseInt(localStorage.getItem("personaId"));
    this.pagoObj.tipoPago = 2;
    this.pagoObj.urlBoleta = "";
    this.pagoObj.pagoId = 0;
    this.pagoObj.estado = 0;
    this.epayService.postPago(this.pagoObj).subscribe( data => 
      {
        this.loading.dismiss();
        // console.log("envio exitosamente pago manual");
        // console.log(data);
        if(data.responseCode === "DR00")
        {
          this.mandarImagen(data.pago);
          this.presentToast("Pago de membresía registrado exitosamente.");
          
        }
        else
          this.presentToast("La boleta no se ha recibido correctamente");

        this.salirModal();


      },
      error =>
      {
        this.loading.dismiss();
        // console.log(error);
        this.presentToast("La boleta no se ha recibido correctamente");
        this.salirModal();

      });

    
  }

  handleFileInput(files: FileList) {
    // console.log(files.item(0));
    this.formData = new FormData();
    this.formData.append('fileKey', files.item(0), files.item(0).name);
  }

  mandarImagen(pagoId)
  {
    // console.log(pagoId);
    var request = new XMLHttpRequest();
    request.open("POST", "https://apiigcpa.digitalrising.com.gt/Pago/Upload/"+ pagoId);
    request.send(this.formData);
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 6000
    });
    toast.present();
  }

  autorizarPago()
  {
    // console.log("llego autorizar pago");
    this.aprobarPago();
 
  }

  aprobarPago()
  {
    this.loading.present();
    this.epayService.putSuscripcion(this.pagoObj.pagoId).subscribe( data => 
    {
      this.loading.dismiss();
      // console.log(data);
      if(data.responseCode === "DR00")
      {
        this.presentToast("Pago de membresía aprobado exitosamente.");
      }
      else
      {
        
        this.presentToast("Ocurrió un error pago de membresía no aprobado.");
      }

      this.salirModal();
    },
    error =>
    {
      this.loading.dismiss();
      // console.log(error);
      this.presentToast("Ocurrió un error pago de membresía no aprobado.");
      this.salirModal();
      
    });
  }

  salirModal()
  {
    this.modalCtrl.dismiss();
  }

  errorImg()
  {
    this.boletaURL = "https://igcpa.org.gt/wp-content/uploads/2021/09/logo-3-1.png";  

  }

  cambiarAnios(event)
  {
    this.montoMembresia = this.montoMembresiaSelect * parseInt(this.aniosMembresia);
    this.pagoObj.meses = 12*parseInt(this.aniosMembresia);

    console.log("Monto menbresia seleccionado: ", this.montoMembresia);
    console.log("Meses seleccionados: ", this.pagoObj.meses);
  }

}
