import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Respuesta } from 'src/app/models/respuesta';
import { ResultadoCarga } from 'src/app/models/resultado-carga';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-upload-archivo',
  templateUrl: './upload-archivo.component.html',
  styleUrls: ['./upload-archivo.component.scss'],
})
export class UploadArchivoComponent implements OnInit {

  formData: FormData = new FormData();
  respuestaCargaObj = new ResultadoCarga(false, "", 0,0,0);
  resultado = new Respuesta(0,"","",0,this.respuestaCargaObj);
  
  constructor(  private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private loading: LoadingService) { }

  ngOnInit() {}

  
  handleFileInput(files: FileList) {
    // console.log(files.item(0));
    this.formData = new FormData();
    this.formData.append('fileKey', files.item(0), files.item(0).name);

    this.cargarExcelUsr();
  }

  async cargarExcelUsr()
  {
    // console.log("empezo a enviar");
    this.loading.present();
     var request = new XMLHttpRequest();
     request.open("POST", "https://apiigcpa.digitalrising.com.gt/Administracion/upload");
     request.setRequestHeader("usuario",localStorage.getItem("personaId").toString());
     request.onload =  () => {
      if (request.readyState === 4) {
        if (request.status === 200) {
          // console.log(request.responseText);
          this.resultado = JSON.parse(request.responseText);
          this.loading.dismiss();
          this.presentToast("Carga de usuarios terminada. \n \n Insertados: " + this.resultado.resultadoCarga.totalInsertados + "\n Con error: " + this.resultado.resultadoCarga.totalErrores + "\n Actualizados: " + this.resultado.resultadoCarga.totalActualizados);

        
        } else {
          // console.error(request.statusText);
          this.loading.dismiss();
          this.presentToast("Carga de personas ha fallado. Vuelve a intentar de nuevo y si el problema persiste verifica que la información ingresada sea correcta");
        }
      }
    };
    request.onerror = () =>  {
      // console.error(request.statusText);
      this.loading.dismiss();
      this.presentToast("Carga de personas ha fallado. Vuelve a intentar de nuevo y si el problema persiste verifica que la información ingresada sea correcta");
    };
    
    request.send(this.formData);

 
    //  console.log(request.response);
    // if (request.readyState == 4)
    //   if (request.status == 200)
    //     var json_data  = request.responseText; 
    //     console.log(json_data);

        // this.respuestaCargaObj = JSON.parse(json_data);
        // console.log(this.respuestaCargaObj);

        // if(this.respuestaCargaObj.exito == true)
        // {
        //   this.presentToast("Cargado con éxito");
        // }
        // else
        // {
        //   this.presentToast("Cargado con error");
        // }

        
  }


  salirModal()
  {
    this.modalCtrl.dismiss();
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 12000
    });
    toast.present();
  }

   resolveAfter2Seconds() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('resolved');
      }, 2000);
    });
  }

}
