import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-pop-menu',
  templateUrl: './pop-menu.component.html',
  styleUrls: ['./pop-menu.component.scss'],
})
export class PopMenuComponent implements OnInit {

  personaId: string;
  usuario: string;
  empresa: string;

  user={user:'',empresa:'',tipo:0,img:''};
  usertype=0;
  usuarioValidoAdmin: boolean = false;
  rol: string;
  esSocioHonorario: boolean = false;

  constructor(private router: Router,
      private popCtrl: PopoverController,
      private authService:UsuarioService) { }

  ngOnInit() {


    this.personaId = localStorage.getItem("personaId");
    this.usuario = localStorage.getItem("usuario");
    this.empresa = localStorage.getItem("empresa");
    this.authService.refresh();
    this.authService.activeUser.subscribe((_user)=>{
      if(_user)
        this.user = _user
      else
        this.user={user:'',empresa:'',tipo:0,img:''};
      
    });

    

  }

  ionViewDidEnter()
  {
    this.personaId = localStorage.getItem("personaId");
    this.rol = localStorage.getItem("rol");
    this.esusuarioValido();
  }
  

  ir(opcion)
  {
    //console.log("llego");
    this.popCtrl.dismiss();
    if(opcion == 1)
      this.router.navigate(['/home-tabs/home']);
    if(opcion == 2)
      this.router.navigate(['/perfil-usuario']);
    if(opcion == 3)
      this.router.navigate(['/pago']);
    if(opcion == 4)
      this.router.navigate(['/usuarios']);
    if(opcion == 5)
      this.router.navigate(['/pagos']);
    if(opcion == 6)
      this.router.navigate(['/pagos-usuario']);
    if(opcion == 7)
      window.open("https://api.whatsapp.com/send?phone=+50251824313");
  }

  logOut()
  {
    delete localStorage["personaId"];
    delete localStorage["usuario"];
    delete localStorage["usuarioId"];
    delete localStorage["email"];
    delete localStorage["empresa"];
    delete localStorage["rol"];
    delete localStorage["objetoUsuario"];
    this.authService.userLogout();
    this.user={user:'',empresa:'',tipo:0,img:''};
    this.popCtrl.dismiss();
    this.router.navigate(['/login']);
  }

  esusuarioValido()
  {
    if(this.personaId != "69" && this.rol == "1")
      this.usuarioValidoAdmin = true;

    if(this.rol == "4")
     this.esSocioHonorario = true;

  }


  errorImg()
  {
    this.user.img = "https://igcpa.org.gt/wp-content/uploads/2021/09/logo-3-1.png";  

  }

}
