import { ResultadoCarga } from "./resultado-carga";

export class Respuesta {

    constructor(
        public messageType: number,
        public responseCode: string,
        public responseMessage: string,
        public pago: number,
        public resultadoCarga?: ResultadoCarga


    ) {}


}
