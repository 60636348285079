import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
   canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'usuarios',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'usuario-registro/:tipo',
    
    loadChildren: () => import('./pages/usuario-registro/usuario-registro.module').then( m => m.UsuarioRegistroPageModule)
  },
  {
    path: 'usuario-exito',
    loadChildren: () => import('./pages/usuario-exito/usuario-exito.module').then( m => m.UsuarioExitoPageModule)
  },
  {
    path: 'recuperar-contrasenia',
    loadChildren: () => import('./pages/recuperar-contrasenia/recuperar-contrasenia.module').then( m => m.RecuperarContraseniaPageModule)
  },
  {
    path: 'nueva-contrasenia/:token',
    loadChildren: () => import('./pages/nueva-contrasenia/nueva-contrasenia.module').then( m => m.NuevaContraseniaPageModule)
  },
  {
    path: 'perfil-usuario',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/perfil-usuario/perfil-usuario.module').then( m => m.PerfilUsuarioPageModule)
  },
  {
    path: 'pago',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pago/pago.module').then( m => m.PagoPageModule)
  },
  {
    path: 'pago-exito',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pago-exito/pago-exito.module').then( m => m.PagoExitoPageModule)
  },
  {
    path: 'home-tabs',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/home-tabs/home-tabs.module').then( m => m.HomeTabsPageModule)
  },
  {
    path: 'revistas',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/revistas/revistas.module').then( m => m.RevistasPageModule)
  },
  {
    path: 'detalle-posts/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/detalle-posts/detalle-posts.module').then( m => m.DetallePostsPageModule)
  },
  {
    path: 'revista-detalle/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/revista-detalle/revista-detalle.module').then( m => m.RevistaDetallePageModule)
  },
  {
    path: 'videos',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'video-detalle/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/video-detalle/video-detalle.module').then( m => m.VideoDetallePageModule)
  },
  {
    path: 'pagos',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pagos/pagos.module').then( m => m.PagosPageModule)
  },
  {
    path: 'pagos-usuario',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pagos-usuario/pagos-usuario.module').then( m => m.PagosUsuarioPageModule)
  },
  {
    path: 'eventos',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/eventos/eventos.module').then( m => m.EventosPageModule)
  },
  {
    path: 'compra-producto',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/compra-producto/compra-producto.module').then( m => m.CompraProductoPageModule)
  },








];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
