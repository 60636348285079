import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Epay } from '../models/epay';
import { EpayResponse } from '../models/epay-response';
import { Pago } from '../models/pago';
import { Respuesta } from '../models/respuesta';
import { Suscripcion } from '../models/suscripcion';

@Injectable({
  providedIn: 'root'
})
export class EpayService {

  baseUrl = 'https://apiigcpa.digitalrising.com.gt';
  constructor(private http: HttpClient) { }


  requestEpay(epayObj: Epay)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': 'c3715741014d66c459ea2e318ac36445'  
      });

    //TOKEN IGCPA: c3715741014d66c459ea2e318ac36445
    //TOKEN SWIGIT PRUEBAS: 7d61fe80096fe9a74b9a240689f002ff
    
    return this.http.post<EpayResponse>("https://epayapi.swigit.gt/venta", epayObj, { headers: headers });
  }

  getIPAddress()
  {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  postPago(pagoObj: Pago)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    //  console.log(pagoObj);
    return this.http.post<Respuesta>(this.baseUrl + "/Pago", pagoObj, { headers: headers });

  }

  getSuscripcion(idusuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      });
    return this.http.get<Suscripcion>(this.baseUrl + "/suscripcion/" + idusuario, {headers: headers});
     
  }

  getPrecioPlan()
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      });
    return this.http.get<number>(this.baseUrl + "/Pago/Precio", {headers: headers});
     
  }

  getPagos(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<Pago>(this.baseUrl + "/Pago", {headers: headers});
  }

  getPagosId(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<Pago>(this.baseUrl + "/Pago/" + localStorage.getItem("personaId").toString(), {headers: headers});
  
    }


    putSuscripcion(pagoId)
    {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'usuario': localStorage.getItem("personaId")
        });
  
      //  console.log(localStorage.getItem("personaId"));
      return this.http.put<Respuesta>(this.baseUrl + "/Pago/" + pagoId, "", { headers: headers });
  
    }

    getPDFPagos(){

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        });
  
      return this.http.get(this.baseUrl + "/Pago/RptPagos", {headers: headers});
    }

}
