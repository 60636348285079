import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderBackComponent } from './header-back/header-back.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { SlideShowComponent } from './slide-show/slide-show.component';
import { PopMenuComponent } from './pop-menu/pop-menu.component';
import { ModalSuscripcionComponent } from './modal-suscripcion/modal-suscripcion.component';
import { FormsModule } from '@angular/forms';
import { UploadArchivoComponent } from './upload-archivo/upload-archivo.component';
import { TarifasComponent } from './tarifas/tarifas.component';



@NgModule({
  declarations: [HeaderBackComponent, MenuComponent, HeaderComponent,
     SlideShowComponent, PopMenuComponent, ModalSuscripcionComponent, UploadArchivoComponent, TarifasComponent],
  exports: [HeaderBackComponent, MenuComponent, HeaderComponent,
     SlideShowComponent, PopMenuComponent, ModalSuscripcionComponent, UploadArchivoComponent, TarifasComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule
  ]
})
export class ComponentsModule { }
