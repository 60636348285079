export class Suscripcion {


    constructor(

        public suscripcionId: number,
        public usuarioId: number,
        public servicio: number,
        public estado: number,
        public fechaVencimiento: Date,
        public pagoId: number



    ) {}

}
