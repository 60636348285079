import { Usuario } from "./usuario";

export class Pago {


    constructor(
        public pagoId: number,
        public usuarioId: number,
        public tipoPago: number,
        public numeroBoleta: string,
        public urlBoleta: string,
        public estado: number,
        public monto: number,
        public fechaPago: Date,
        public usuario?: Usuario,
        public autorizacion?: number,
        public meses?: number
       

    ) {}


}
