import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(arreglo: any[], texto: string, columna: string, columna2: string, columna3: string): any[] {

    if(arreglo){
      if(texto === ''){
        return arreglo;
      }
      
      texto = texto.toLowerCase();  
      return arreglo.filter(item => {    
        if(columna2 != '' && columna2 && columna3 == null)
        {

          if(columna === 'persona' && columna2 != 'estado'){
            let aux = item[columna]['nombre'];
            
            if(aux.toLowerCase().includes(texto)){
              return item;
            }
              
          }else{

            let aux = item[columna2];
            if(aux == texto){
           
              return item;
            }

          }
        }
        else if(columna2 != '' && columna2 && columna3 && columna3 != '')
        {
          console.log("llego opcion de 3 columnas");
          console.log(item);
          console.log(columna);
          console.log(columna2);
          console.log(columna3);
          console.log(texto);

          let aux = item[columna][columna2]['nombre1']+" "+item[columna][columna2]['apellido1']+" ";
  
          if(item[columna][columna2]['nombre2'])
            aux += item[columna][columna2]['nombre2'] + " ";

          if(item[columna][columna2]['apellido2'])
            aux += item[columna][columna2]['apellido2'];
          
          if(aux.toLowerCase().includes(texto)){
            return item;
          }
        }
        else
        {

            if(columna != 'estado')
            {
              if(item[columna].toLowerCase().includes(texto)){
                return item;
              }
            }
            else
            {
              let aux = item[columna];
              if(aux == texto){
                return item;
              }

            }

        }
        
      })
    }
    return arreglo;
  }

}
