import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private authService: UsuarioService,private router: Router) { }

  canActivate(): boolean{
    let value = this.authService.isAuthenticated();
    if (!value) {
      this.router.navigateByUrl("/login");
    }
    return value;
  }

}
