export class ResultadoCarga {


    constructor(
        public exito: boolean,
        public mensaje: string,
        public totalActualizados: number,
        public totalInsertados: number,
        public totalErrores: number


    ) {}

}
