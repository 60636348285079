import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { Usuario } from '../models/usuario';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Respuesta } from '../models/respuesta';
import { Persona } from '../models/persona';

const USER = 'user';
const USERTYPE = 'tipo';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  baseUrl = 'https://apiigcpa.digitalrising.com.gt/Usuario';
  authenticationState = new BehaviorSubject(false);
  activeUser = new BehaviorSubject({user:'',empresa:'',tipo:0,img:''});
  public usuario: any;

 
  constructor( private http: HttpClient, private storage: Storage, private platform: Platform) { 

  }


  getUsuarios(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    return this.http.get(this.baseUrl, {headers: headers});
  }

  getUsuario(idusuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get(this.baseUrl + "/" + idusuario, {headers: headers});
     
  }

  getUsuarioId(idusuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get<Usuario>(this.baseUrl + "/" +  idusuario, {headers: headers});
     
  }

  getUsuarioLogin(usuarioLogin)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
 
    return this.http.post(this.baseUrl + '/Login', usuarioLogin, {headers: headers});
     
  }

  isAuthenticated(){
    return this.authenticationState.value;
  }

 
  setUserInfo(user){
    return this.storage.set(USER,user).then(res => {
      this.activeUser.next(user);
      this.authenticationState.next(true);
    });
  }

  refresh(){
    this.storage.get(USER).then(res => {
      if(res){
        this.activeUser.next(res);
        this.authenticationState.next(true);
      }else{
        this.activeUser.next({user:'',empresa:'',tipo:0,img:''});
        this.authenticationState.next(false);
      }
      
    });

  }

  userLogout(){
    this.storage.remove(USER).then(res => {
      this.authenticationState.next(false);
      this.activeUser.next({user:'',empresa:'',tipo:0,img:''});
    });
  }

  postUsuario(usuario)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + '/CrearUsuario', usuario, { headers: headers });
  }

  putUsuario(usuario)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    
    return this.http.put(this.baseUrl + '/ModificarUsuario', usuario, { headers: headers });
  }

  putContrasenia(recuperacionContrasenia)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': recuperacionContrasenia.token
      });
    this.http.put(this.baseUrl + '/NuevaContrasenia', recuperacionContrasenia, { headers: headers }).subscribe();
  }

  reestablecerContrasenia(usuario)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.http.put(this.baseUrl + '/ModificarUsuario', usuario, { headers: headers }).subscribe();
  }


  correoRecuperacion(recuperacionContrasenia)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + '/RecuperarContrasenia', recuperacionContrasenia, { headers: headers });
  }

  getPDFUsuarios(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      });

    return this.http.get(this.baseUrl + "/RptUsuarios", {headers: headers});
  }

  getAsociado(codigoAsociado: string)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.get<Persona>("https://apiigcpa.digitalrising.com.gt/Persona/" +  codigoAsociado, {headers: headers});
  }

  postUsuarioAsociado(usuario)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.post<Respuesta>(this.baseUrl + '/CrearUsuarioPerExistente', usuario, { headers: headers });
  }

 
}
