import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.scss'],
})
export class SlideShowComponent implements OnInit {


  
  slideOpts = {
    autoplay: true,
    speed: 1000
  };

  constructor() { }

  ngOnInit() {}

}
